import _ from 'lodash';
import { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import BlankLayout from '../layouts/BlankLayout';
import Loadable from '../layouts/loader/Loadable';
import { ExtendedStore } from '../types';
import { MODULE_PERMISSION, ROUTER } from '../utils';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const Dashboard = Loadable(lazy(() => import("../views/dashboard/dashboard")));
const SupersetDashboard = Loadable(lazy(() => import("../views/superset-dashboard/superset-dashboard")));
const SiteManagement = Loadable(lazy(
  () => import("../views/site-management/site-management")
));
const Users = Loadable(lazy(() => import("../views/users/users")));
const MeterGroup = Loadable(lazy(() => import("../views/meter-group/meter-group")));
const DeviceStatus = Loadable(lazy(() => import("../views/device-status/device-status")));
const Role = Loadable(lazy(() => import("../views/role/role")));
const Meter = Loadable(lazy(() => import("../views/meter/meter")));
const Contact = Loadable(lazy(() => import("../views/contact/contact")));
const MeterDataAudit = Loadable(lazy(
  () => import("../views/meter-data-audit/meter-data-audit")
));
const MeterStatus = Loadable(lazy(() => import("../views/meter-status/meter-status")));
const UnprovisionedMeter = Loadable(lazy(
  () => import("../views/unprovisioned-meter/unprovisioned-meter")
));
const VirtualMeters = Loadable(lazy(
  () => import("../views/virtual-meters/virtual-meters")
));
const DashboardConfiguration = Loadable(lazy(() => import("../views/dashboard-configuration/dashboard-configuration")));

const NEM12Component = Loadable(lazy(() => import("../views/nem12/nem12.component")));
const Profile = Loadable(lazy(() => import("../views/profile/profile")));
const ImportMeters = Loadable(lazy(() => import("../views/import-meters/import-tab")));
const ApiMenu = Loadable(lazy(() => import("../views/api-menu/api-menu")));
const DataSync = Loadable(lazy(() => import("../views/data-sync/data-sync")));
const ScheduleImport = Loadable(lazy(() => import("../views/schedule-import/schedule-import")));
const Reports = Loadable(lazy(() => import("../views/reports/reports")));
const ValidationData = Loadable(lazy(() => import("../views/validation-data/validation-data")));
const DataValidationReport = Loadable(lazy(() => import("../views/data-validation-report/data-validation-report")));
const CleanUpLogs = Loadable(lazy(() => import("../views/clean-up-logs/clean-up-logs")));
const ViewReports = Loadable(lazy(() => import("../views/view-reports/view-reports")));
const MeterDataCorrection = Loadable(lazy(() => import("../views/meter-data-correction/meter-data-correction")));
const MeterSchedule = Loadable(lazy(() => import("../views/meter-schedule/meter-schedule")));
const NEM12QualityFlagReports = Loadable(lazy(() => import("../views/nem12-quality-flag-report/nem12-quality-flag-reports")));
const MeterEventReports = Loadable(lazy(() => import("../views/meter-event-report/meter-event-report")));
const NEM12Report = Loadable(lazy(() => import("../views/nem12-report/nem12-report")));
const MDNReport = Loadable(lazy(() => import("../views/mdn-report/mdn-report")));
const NotificationAcknowledge = Loadable(lazy(() => import("../views/notification-acknowledge/notification-acknowledge")));
const ContactSupport = Loadable(lazy(() => import("../views/contact-support/contact-support")));
const MeterComparison = Loadable(lazy(() => import("../views/meter-comparison/meter_comparison")));
const DataPreview = Loadable(lazy(() => import("../views/data-preview/data-preview")));
const CompanyConfiguration = Loadable((lazy(() => import("../views/company-configuration/company-configuration"))));
const ContactSupportList = Loadable((lazy(() => import("../views/contact-support/contact-support-grid"))));
const UnprovisionedIotDevices = Loadable((lazy(() => import("../views/unprovisioned-iot-devices/unprovisioned-iot-devices"))));
const IOTPayloadConfiguration = Loadable((lazy(() => import("../views/event-alarm-configuration/iot-payload-configuration"))));
const MeterAlarmEvent = Loadable((lazy(() => import("../views/meter-alarm-event/meter-alarm-event"))));
const NMIGenerator = Loadable((lazy(() => import("../views/meter-propertys-generator/nmi-generator/nmi-generator"))));
const MeterDataSync = Loadable((lazy(() => import("../views/meter-data-sync/meter-data-sync"))));
const SerialGenerator = Loadable((lazy(() => import("../views/meter-propertys-generator/serial-number-generator/serial-number-generator"))));
const PropertyGenerator = Loadable((lazy(() => import("../views/meter-propertys-generator/property-number-generator/property-number-generator"))));
const AdvancedEcoDataSync = Loadable((lazy(() => import("../views/advanced-eco-data-sync/advanced-eco-data-sync"))));
const AdvancedEcoValidationSummary = Loadable((lazy(() => import("../views/advance-eco-validate-summary/AdvanceEcoValidateSummary"))));
const DataValidationRulesSummary = Loadable((lazy(() => import("../views/data-validation-report/data-validation-rules-summary"))));

const UserAudit = Loadable((lazy(() => import("../views/user-audit/user-audit-grid"))));

const NotFound = Loadable(lazy(() => import("../views/404")));




/*******MDN Routes ************/
/*****Routes******/

const getUserRoutes = (abilities: any, assignedPermissions: any) => {
  const MenuSidebarData = initialRoutes;
  const filterRoute: any[] = [];
  const modulePermission: any[] = [];
  if (assignedPermissions && abilities && abilities.length) {
    const permissionsData = abilities.map((item: any) => item.action);
    Object.keys(assignedPermissions).map((key) => {
      modulePermission.push(key.toLowerCase());
    });
    if (modulePermission.length && abilities.length) {
      MenuSidebarData.forEach((item: any) => {
        if (_.intersectionWith(item.permission, permissionsData, _.isEqual).length) {
          filterRoute.push(item);
        }
        else if (item?.permission?.length === 0) {
          filterRoute.push(item);
        }
      });
    }
  }
  return filterRoute;
}

const initialRoutes = [
  {
    path: '/',
    name: 'Home',
    element: <Navigate to={ROUTER.DASHBOARD} />,
    permission: []
  },
  {
    path: ROUTER.DASHBOARD,
    name: 'dashboards',
    exact: true,
    element: <Dashboard />,
    permission: []
  },
  {
    path: ROUTER.SUPERSET_DASHBOARD,
    name: 'SUPERSET_DASHBOARD',
    exact: true,
    element: <SupersetDashboard />,
    permission: []
  },
  {
    path: ROUTER.NEM12,
    name: 'NEM12',
    exact: true,
    element: <NEM12Component />,
    permission: [MODULE_PERMISSION.NEM12.VIEW]
  },
  {
    path: ROUTER.METER_GROUP,
    name: 'METER_GROUP',
    exact: true,
    element: <MeterGroup />,
    permission: [
      MODULE_PERMISSION.METER_GROUP.ADD,
      MODULE_PERMISSION.METER_GROUP.EDIT,
      MODULE_PERMISSION.METER_GROUP.VIEW,
      MODULE_PERMISSION.METER_GROUP.DELETE,
    ]
  },
  {
    path: ROUTER.DEVICE_STATUS,
    name: 'DEVICE_STATUS',
    exact: true,
    element: <DeviceStatus />,
    permission: [
      MODULE_PERMISSION.DEVICE_STATUS.VIEW,
    ]
  },
  {
    path: ROUTER.METER,
    name: 'METER',
    exact: true,
    element: <Meter />,
    permission: [
      MODULE_PERMISSION.METER.ADD,
      MODULE_PERMISSION.METER.EDIT,
      MODULE_PERMISSION.METER.VIEW,
      MODULE_PERMISSION.METER.DELETE,
    ]
  },
  {
    path: ROUTER.METER_DATA_AUDIT,
    name: 'METER_DATA_AUDIT',
    exact: true,
    element: <MeterDataAudit />,
    permission: [
      MODULE_PERMISSION.METER.VIEW_AUDIT
    ]
  },
  {
    path: ROUTER.METER_STATUS,
    name: 'METER_STATUS',
    exact: true,
    element: <MeterStatus />,
    permission: [
      MODULE_PERMISSION.METER_STATUS.ADD,
      MODULE_PERMISSION.METER_STATUS.EDIT,
      MODULE_PERMISSION.METER_STATUS.VIEW,
      MODULE_PERMISSION.METER_STATUS.DELETE,
    ]
  },
  {
    path: ROUTER.UNPROVISIONED_METER,
    name: 'UNPROVISIONED_METER',
    exact: true,
    element: <UnprovisionedMeter />,
    permission: [MODULE_PERMISSION.UNPROVISIONED_METER.VIEW]
  },
  {
    path: ROUTER.IMPORT_METERS,
    name: 'IMPORT_METERS',
    exact: true,
    element: <ImportMeters />,
    permission: [MODULE_PERMISSION.METER.DATA_INGESTION]
  },
  {
    path: ROUTER.VIRTUAL_METERS,
    name: 'VIRTUAL_METERS',
    exact: true,
    element: <VirtualMeters />,
    permission: [
      MODULE_PERMISSION.VIRTUAL_METER.ADD,
      MODULE_PERMISSION.VIRTUAL_METER.EDIT,
      MODULE_PERMISSION.VIRTUAL_METER.VIEW,
      MODULE_PERMISSION.VIRTUAL_METER.DELETE,
    ]
  },
  {
    path: ROUTER.SITE_MANAGEMENT,
    name: 'SITE_MANAGEMENT',
    exact: true,
    element: <SiteManagement />,
    permission: [
      MODULE_PERMISSION.SITE_MANAGEMENT.EDIT,
      MODULE_PERMISSION.SITE_MANAGEMENT.VIEW,
      MODULE_PERMISSION.SITE_MANAGEMENT.DELETE,
    ]
  },
  {
    path: ROUTER.USER,
    name: 'USER',
    exact: true,
    element: <Users />,
    permission: [
      MODULE_PERMISSION.USER.ADD,
      MODULE_PERMISSION.USER.EDIT,
      MODULE_PERMISSION.USER.VIEW,
      MODULE_PERMISSION.USER.DELETE,
    ]
  },
  {
    path: ROUTER.USER_AUDIT,
    name: 'USER_AUDIT',
    exact: true,
    element: <UserAudit />,
    permission: [
      MODULE_PERMISSION.LOGS.USERAUDIT
    ]
  },
  {
    path: ROUTER.ROLE,
    name: 'ROLE',
    exact: true,
    element: <Role />,
    permission: [
      MODULE_PERMISSION.USER_ROLE.ADD,
      MODULE_PERMISSION.USER_ROLE.EDIT,
      MODULE_PERMISSION.USER_ROLE.VIEW,
      MODULE_PERMISSION.USER_ROLE.DELETE,
    ]
  },
  {
    path: ROUTER.CONTACT,
    name: 'CONTACT',
    exact: true,
    element: <Contact />,
    permission: [
      MODULE_PERMISSION.CONTACT.ADD,
      MODULE_PERMISSION.CONTACT.EDIT,
      MODULE_PERMISSION.CONTACT.VIEW,
      MODULE_PERMISSION.CONTACT.DELETE,
    ]
  },
  {
    path: ROUTER.API_MENU,
    name: 'API_MENU',
    exact: true,
    element: <ApiMenu />,
    permission: []
  },
  {
    path: ROUTER.PROFILE,
    name: 'PROFILE',
    exact: true,
    element: <Profile />,
    permission: []
  },
  {
    path: ROUTER.DATA_SYNC,
    name: 'DATA_SYNC',
    exact: true,
    element: <DataSync />,
    permission: [MODULE_PERMISSION.CONFIGURATION.CONFIGURE_METER_CHANNEL,]
  },
  {
    path: ROUTER.DASHBOARD_CONFIGURATION,
    name: 'DASHBOARD_CONFIGURATION',
    exact: true,
    element: <DashboardConfiguration />,
    permission: [MODULE_PERMISSION.CONFIGURATION.CONFIGURE_METER_CHANNEL,]
  },
  {
    path: ROUTER.SCHEDULE_LOGS,
    name: 'SCHEDULE_LOGS',
    exact: true,
    element: <ScheduleImport />,
    permission: [MODULE_PERMISSION.CONFIGURATION.VIEW_LOGS]
  },
  {
    path: ROUTER.REPORTS,
    name: 'REPORTS',
    exact: true,
    element: <Reports />,
    permission: [
      MODULE_PERMISSION.SCHEDULE_REPORT.ADD,
      MODULE_PERMISSION.SCHEDULE_REPORT.EDIT,
      MODULE_PERMISSION.SCHEDULE_REPORT.VIEW,
      MODULE_PERMISSION.SCHEDULE_REPORT.DELETE,
    ]
  },
  {
    path: ROUTER.VIEWREPORTS,
    name: 'VIEW_REPORTS',
    exact: true,
    element: <ViewReports />,
    permission: [
      MODULE_PERMISSION.REPORT_VIEW.NEM12_REPORT_VIEW,
      MODULE_PERMISSION.REPORT_VIEW.NEM12_QUALITY_FLAG_REPORT_VIEW,
      MODULE_PERMISSION.REPORT_VIEW.MDN_REPORT_VIEW,
      MODULE_PERMISSION.REPORT_VIEW.ENEM12_REPORT_VIEW,
      MODULE_PERMISSION.REPORT_VIEW.METER_EVENT_REPORT_VIEW
    ]
  },
  {
    path: ROUTER.METERDATACORRECTION,
    name: 'METER_DATA_CORRECTION',
    exact: true,
    element: <MeterDataCorrection />,
    permission: [
      MODULE_PERMISSION.METER_DATA_CORRECTION.METER_DATA_CORRECTION_VIEW,
    ]
  },
  {
    path: ROUTER.VALIDATION_DATA,
    name: 'VALIDATION_DATA',
    exact: true,
    element: <ValidationData />,
    permission: [
      MODULE_PERMISSION.VALIDATION.ADD,
      MODULE_PERMISSION.VALIDATION.EDIT,
      MODULE_PERMISSION.VALIDATION.DELETE,
      MODULE_PERMISSION.VALIDATION.VIEW,
    ]
  },
  {
    path: ROUTER.DATA_VALIDATION_REPORT,
    name: 'DATA_VALIDATION_REPORT',
    exact: true,
    element: <DataValidationReport />,
    permission: [
      MODULE_PERMISSION.VIEW_DATA_VALIDATION_REPORT.DATA_VALIDATION_REPORT_VIEW,
    ]
  },
  {
    path: ROUTER.CLEAN_UP_LOGS,
    name: 'CLEAN_UP_LOGS',
    exact: true,
    element: <CleanUpLogs />,
    permission: [MODULE_PERMISSION.CONFIGURATION.VIEW_LOGS]
  },
  {
    path: ROUTER.METER_SCHEDULE,
    name: 'METER_SCHEDULE',
    exact: true,
    element: <MeterSchedule />,
    permission: [
      MODULE_PERMISSION.METER_SCHEDULE.VIEW,
      MODULE_PERMISSION.METER_SCHEDULE.ADD,
    ]
  },
  {
    path: ROUTER.NEM12_QUALITY_FLAG,
    name: 'NEM12_QUALITY_FLAG',
    exact: true,
    element: <NEM12QualityFlagReports />,
    permission: [
      MODULE_PERMISSION.NEM12_QUALITY_FLAG_REPORT.ADD,
      MODULE_PERMISSION.NEM12_QUALITY_FLAG_REPORT.EDIT,
      MODULE_PERMISSION.NEM12_QUALITY_FLAG_REPORT.VIEW,
      MODULE_PERMISSION.NEM12_QUALITY_FLAG_REPORT.DELETE,
    ]
  },
  {
    path: ROUTER.METER_EVENT_REPORT,
    name: 'METER_EVENT_REPORT',
    exact: true,
    element: <MeterEventReports />,
    permission: [
      MODULE_PERMISSION.METER_EVENT_REPORT.ADD,
      MODULE_PERMISSION.METER_EVENT_REPORT.EDIT,
      MODULE_PERMISSION.METER_EVENT_REPORT.VIEW,
      MODULE_PERMISSION.METER_EVENT_REPORT.DELETE,
    ]
  },
  {
    path: ROUTER.NEM12_REPORT,
    name: 'NEM12_REPORT',
    exact: true,
    element: <NEM12Report />,
    permission: [
      MODULE_PERMISSION.NEM12_REPORT.ADD,
      MODULE_PERMISSION.NEM12_REPORT.EDIT,
      MODULE_PERMISSION.NEM12_REPORT.VIEW,
      MODULE_PERMISSION.NEM12_REPORT.DELETE,
    ]
  },
  {
    path: ROUTER.MDN_REPORT,
    name: 'MDN_REPORT',
    exact: true,
    element: <MDNReport />,
    permission: [
      MODULE_PERMISSION.MDN_REPORT.ADD,
      MODULE_PERMISSION.MDN_REPORT.EDIT,
      MODULE_PERMISSION.MDN_REPORT.VIEW,
      MODULE_PERMISSION.MDN_REPORT.DELETE,
    ]
  },
  {
    path: ROUTER.NOTIFICATION_ACKNOWLEDGE,
    name: 'NOTIFICATION_ACKNOWLEDGE',
    exact: true,
    element: <NotificationAcknowledge />,
    permission: []
  },
  {
    path: ROUTER.CONTACT_SUPPORT,
    name: 'CONTACT_SUPPORT',
    exact: true,
    element: <ContactSupport />,
    permission: []
  },
  {
    path: ROUTER.METER_COMPARISON,
    name: 'METER_COMPARISON',
    exact: true,
    element: <MeterComparison />,
    permission: []
  },
  {
    path: ROUTER.DATA_PREVIEW,
    name: 'DATA_PREVIEW',
    exact: true,
    element: <DataPreview />,
    permission: []
  },
  {
    path: ROUTER.COMPANY_CONFIGURATION,
    name: 'COMPANY_CONFIGURATION',
    exact: true,
    element: <CompanyConfiguration />,
    permission: []
  },
  {
    path: ROUTER.CONTACT_SUPPORT_LIST,
    name: 'CONTACT_SUPPORT_LIST',
    exact: true,
    element: <ContactSupportList />,
    permission: [MODULE_PERMISSION.CONFIGURATION.CONTACT_SUPPORT_VIEW]
  },
  {
    path: ROUTER.UNPROVISIONED_IOT_DEVICES,
    name: 'UNPROVISIONED_IOT_DEVICES',
    exact: true,
    element: <UnprovisionedIotDevices />,
    permission: [MODULE_PERMISSION.CONFIGURATION.CONFIGURE_METER_CHANNEL,]
  },
  {
    path: ROUTER.IOT_PAYLOAD_PROPERTY,
    name: 'IOT_PAYLOAD_PROPERTY',
    exact: true,
    element: <IOTPayloadConfiguration />,
    permission: [MODULE_PERMISSION.CONFIGURATION.CONFIGURE_METER_CHANNEL]
  },
  {
    path: ROUTER.METER_ALARM_EVENT,
    name: 'METER_ALARM_EVENT',
    exact: true,
    element: <MeterAlarmEvent />,
    permission: [MODULE_PERMISSION.CONFIGURATION.CONFIGURE_METER_CHANNEL]
  },
  {
    path: ROUTER.NMI_GENERATOR,
    name: 'NMI_GENERATOR',
    exact: true,
    element: <NMIGenerator />,
    permission: [MODULE_PERMISSION.METER.NMI_GENERATOR]
  },
  {
    path: ROUTER.CET_DATA_SYNC,
    name: 'CET_DATA_SYNC',
    exact: true,
    element: <MeterDataSync />,
    permission: [
      MODULE_PERMISSION.DATA_SYNC.CET_DATA_SYNC
    ]
  },
  {
    path: ROUTER.SERIAL_GENERATOR,
    name: 'SERIAL_GENERATOR',
    exact: true,
    element: <SerialGenerator />,
    permission: [MODULE_PERMISSION.METER.SERIAL_GENERATOR]
  },
  {
    path: ROUTER.PROPERTY_GENERATOR,
    name: 'PROPERTY_GENERATOR',
    exact: true,
    element: <PropertyGenerator />,
    permission: [MODULE_PERMISSION.METER.PROPERTY_GENERATOR]
  },
  {
    path: ROUTER.ADVANCED_ECO_DATA_SYNC,
    name: 'ADVANCED_ECO_DATA_SYNC',
    exact: true,
    element: <AdvancedEcoDataSync />,
    permission: [
      MODULE_PERMISSION.DATA_SYNC.ADVANCED_ECO_DATA_SYNC
    ]
  },
  {
    path: ROUTER.ADVANCED_ECO_VALIDATION_SUMMARY,
    name: 'ADVANCED_ECO_VALIDATION_SUMMARY',
    exact: true,
    element: <AdvancedEcoValidationSummary />,
    permission: [
      MODULE_PERMISSION.VALIDATION_SUMMARY.ADVANCED_ECO_VALIDATION_SUMMARY_VIEW
    ]
  },
  {
    path: ROUTER.DATA_VALIDATION_REPORT_RULS,
    name: 'DATA_VALIDATION_REPORT_RULS',
    exact: true,
    element: <DataValidationRulesSummary />,
    permission: [
      MODULE_PERMISSION.VIEW_DATA_VALIDATION_REPORT.DATA_VALIDATION_REPORT_VIEW
    ]
  },
  {
    path: '*',
    element: <Navigate to="/auth/404" />,
    permission: []
  }
]

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to={ROUTER.DASHBOARD} /> },
      { path: ROUTER.DASHBOARD, name: 'dashboards', exact: true, element: <Dashboard /> },
      { path: ROUTER.NEM12, name: 'NEM12', exact: true, element: <NEM12Component /> },
      { path: ROUTER.METER_GROUP, name: 'METER_GROUP', exact: true, element: <MeterGroup /> },
      { path: ROUTER.DEVICE_STATUS, name: 'DEVICE_STATUS', exact: true, element: <DeviceStatus /> },
      { path: ROUTER.METER, name: 'METER', exact: true, element: <Meter /> },
      { path: ROUTER.METER_DATA_AUDIT, name: 'METER_DATA_AUDIT', exact: true, element: <MeterDataAudit /> },
      { path: ROUTER.METER_STATUS, name: 'METER_STATUS', exact: true, element: <MeterStatus /> },
      { path: ROUTER.UNPROVISIONED_METER, name: 'UNPROVISIONED_METER', exact: true, element: <UnprovisionedMeter /> },
      { path: ROUTER.IMPORT_METERS, name: 'IMPORT_METERS', exact: true, element: <ImportMeters /> },
      { path: ROUTER.VIRTUAL_METERS, name: 'VIRTUAL_METERS', exact: true, element: <VirtualMeters /> },
      { path: ROUTER.SITE_MANAGEMENT, name: 'SITE_MANAGEMENT', exact: true, element: <SiteManagement /> },
      { path: ROUTER.USER, name: 'USER', exact: true, element: <Users /> },
      { path: ROUTER.USER_AUDIT, name: 'USER_AUDIT', exact: true, element: <UserAudit /> },
      { path: ROUTER.ROLE, name: 'ROLE', exact: true, element: <Role /> },
      { path: ROUTER.CONTACT, name: 'CONTACT', exact: true, element: <Contact /> },
      { path: ROUTER.API_MENU, name: 'API_MENU', exact: true, element: <ApiMenu /> },
      { path: ROUTER.PROFILE, name: 'PROFILE', exact: true, element: <Profile /> },
      { path: ROUTER.DATA_SYNC, name: 'DATA_SYNC', exact: true, element: <DataSync /> },
      { path: ROUTER.DASHBOARD_CONFIGURATION, name: 'DASHBOARD_CONFIGURATION', exact: true, element: <DashboardConfiguration /> },
      { path: ROUTER.SCHEDULE_LOGS, name: 'SCHEDULE_LOGS', exact: true, element: <ScheduleImport /> },
      { path: ROUTER.REPORTS, name: 'REPORTS', exact: true, element: <Reports /> },
      { path: ROUTER.METERDATACORRECTION, name: 'METER_DATA_CORRECTION', exact: true, element: <MeterDataCorrection /> },
      { path: ROUTER.VIEWREPORTS, name: 'VIEW_REPORTS', exact: true, element: <ViewReports /> },
      { path: ROUTER.VALIDATION_DATA, name: 'VALIDATION_DATA', exact: true, element: <ValidationData /> },
      { path: ROUTER.DATA_VALIDATION_REPORT, name: 'DATA_VALIDATION_REPORT', exact: true, element: <DataValidationReport /> },
      { path: ROUTER.CLEAN_UP_LOGS, name: 'CLEAN_UP_LOGS', exact: true, element: <CleanUpLogs /> },
      { path: ROUTER.METER_SCHEDULE, name: 'METER_SCHEDULE', exact: true, element: <MeterSchedule /> },
      { path: ROUTER.NEM12_QUALITY_FLAG, name: 'NEM12_QUALITY_FLAG', exact: true, element: <NEM12QualityFlagReports /> },
      { path: ROUTER.METER_EVENT_REPORT, name: 'METER_EVENT_REPORT', exact: true, element: <MeterEventReports /> },
      { path: ROUTER.NOTIFICATION_ACKNOWLEDGE, name: 'NOTIFICATION_ACKNOWLEDGE', exact: true, element: <NotificationAcknowledge /> },
      { path: ROUTER.CONTACT_SUPPORT_LIST, name: 'CONTACT_SUPPORT_LIST', exact: true, element: <ContactSupportList /> },
      { path: ROUTER.IOT_PAYLOAD_PROPERTY, name: 'IOT_PAYLOAD_CONFIGURATION', exact: true, element: <IOTPayloadConfiguration /> },
      { path: ROUTER.NMI_GENERATOR, name: 'NMI_GENERATOR', exact: true, element: <NMIGenerator /> },
      { path: ROUTER.CET_DATA_SYNC, name: 'METER_DATA_SYNC', exact: true, element: <MeterDataSync /> },
      { path: ROUTER.SERIAL_GENERATOR, name: 'SERIAL_GENERATOR', exact: true, element: <SerialGenerator /> },
      { path: ROUTER.PROPERTY_GENERATOR, name: 'PROPERTY_GENERATOR', exact: true, element: <PropertyGenerator /> },
      { path: ROUTER.ADVANCED_ECO_DATA_SYNC, name: 'ADVANCED_ECO_DATA_SYNC', exact: true, element: <AdvancedEcoDataSync /> },
      { path: ROUTER.ADVANCED_ECO_VALIDATION_SUMMARY, name: 'ADVANCED_ECO_VALIDATION_SUMMARY', exact: true, element: <AdvancedEcoValidationSummary /> },
      { path: ROUTER.DATA_VALIDATION_REPORT_RULS, name: 'DATA_VALIDATION_REPORT_RULS', exact: true, element: <DataValidationRulesSummary /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <NotFound /> },
    ],
  },
];

export const getThemeRoutes = (props: any) => {
  const abilities = useSelector((state: ExtendedStore) => state.settings.abilities);
  const assignedPermissions = useSelector((state: ExtendedStore) => state.settings.assignedPermissions);
  const routes = getUserRoutes(abilities, assignedPermissions);

  return [
    {
      path: '/',
      element: <FullLayout {...props} />,
      children: routes
    },
    {
      path: '/auth',
      element: <FullLayout {...props} />,
      children: [
        { path: '404', element: <NotFound /> },
      ],
    },
  ];
}

export default ThemeRoutes;
